var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "ma-auto",
              attrs: { width: "50vw", height: "50vh" }
            },
            [
              _c("v-row", { staticClass: "my-12" }, [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black text-center fx-20 mx-auto",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.agency.login")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("v-row", [
                _c(
                  "p",
                  {
                    staticClass: "text-center fx-20 mx-auto",
                    staticStyle: { "font-size": "24px", color: "red" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("description.maintenance")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("v-row", [
                _c(
                  "p",
                  {
                    staticClass: "text-center fx-20 mx-auto",
                    staticStyle: { "font-size": "16px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("description.maintenanceMessage")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }